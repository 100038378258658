<template>
	<div class="supplier-order-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !poNo"
		         v-on:ok="deletePurchaseOrder"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Purchase Order</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalPoNo" class="font-weight-bold">PO No.</label>
					<input type="text" id="modalPoNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': poNoInvalid}"
					       v-model="poNo"
					       v-on:input="poNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="savePurchaseOrder"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!purchaseOrder"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="poNo" class="font-weight-bold">PO No.</label>
					<input type="text" id="poNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localPurchaseOrder.poNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="supplierQuote" class="font-weight-bold">Supplier Quote</label>
					<input type="text" id="supplierQuote" class="form-control position-relative"
					       placeholder="Choose Supplier Quote From The Table"
					       v-bind:class="invalidInputs.supplierQuote"
					       v-bind:value="localPurchaseOrder.quoteNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="supplier" class="font-weight-bold">Supplier</label>
					<input type="text" id="supplier" class="form-control position-relative"
					       placeholder="Choose Supplier From The Table"
					       v-bind:class="invalidInputs.supplier"
					       v-bind:value="localPurchaseOrder.supplierName" readonly>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localPurchaseOrder.items" v-bind:key="index">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localPurchaseOrder.items.length === 1"
						        v-on:click="addDeleteItems('delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localPurchaseOrder.items.length >= 50"
						        v-on:click="addDeleteItems('add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`item-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`item-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
							<input type="text" v-bind:id="`item-unitPrice${index}`" placeholder="Unit Price"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].unitPrice"
							       v-bind:value="item.unitPrice"
							       v-on:change="updateItems('unitPrice', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="currency" class="font-weight-bold">Currency</label>
					<select id="currency" class="form-control position-relative"
					        v-bind:class="invalidInputs.currency"
					        v-bind:value="localPurchaseOrder.currency"
					        v-on:change="updatePurchaseOrder('currency', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>HKD</option>
						<option>USD</option>
						<option>RMB</option>
						<option>JPY</option>
						<option>CAD</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="serviceFee" class="font-weight-bold">Service Fee</label>
					<input type="text" id="serviceFee" placeholder="Service Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.serviceFee"
					       v-bind:value="localPurchaseOrder.serviceFee"
					       v-on:change="updatePurchaseOrder('serviceFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="shippingFee" class="font-weight-bold">Shipping Fee</label>
					<input type="text" id="shippingFee" placeholder="Shipping Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.shippingFee"
					       v-bind:value="localPurchaseOrder.shippingFee"
					       v-on:change="updatePurchaseOrder('shippingFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="otherCharges" class="font-weight-bold">Other Charges</label>
					<input type="text" id="otherCharges" placeholder="Other Charges"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.otherCharges"
					       v-bind:value="localPurchaseOrder.otherCharges"
					       v-on:change="updatePurchaseOrder('otherCharges', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="tax" class="font-weight-bold">Tax</label>
					<input type="text" id="tax" placeholder="Tax"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.tax"
					       v-bind:value="localPurchaseOrder.tax"
					       v-on:change="updatePurchaseOrder('tax', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="discount" class="font-weight-bold">Discount</label>
					<input type="text" id="discount" placeholder="Discount"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.discount"
					       v-bind:value="localPurchaseOrder.discount"
					       v-on:change="updatePurchaseOrder('discount', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="totalAmount" class="font-weight-bold">Total Amount</label>
					<input type="text" id="totalAmount" placeholder="0.00"
					       class="form-control position-relative"
					       v-bind:value="localPurchaseOrder.totalAmount" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="paymentTerms" class="font-weight-bold">Payment Terms</label>
					<input type="text" id="paymentTerms" placeholder="Payment Terms"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.paymentTerms"
					       v-bind:value="localPurchaseOrder.paymentTerms"
					       v-on:input="updatePurchaseOrder('paymentTerms', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="shippingAddress" class="font-weight-bold">Shipping Address</label>
					<input type="text" id="shippingAddress" placeholder="Shipping Address"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.shippingAddress"
					       v-bind:value="localPurchaseOrder.shippingAddress"
					       v-on:input="updatePurchaseOrder('shippingAddress', $event.target.value)">
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="deliverAtDate" class="font-weight-bold text-truncate">Delivery Date</label>
						<b-form-datepicker id="deliverAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.deliverAtDT.date"
						                   v-bind:value="localPurchaseOrder.deliverAtDT.date"
						                   v-on:input="updateDT('deliverAt', 'deliverAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="deliverAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="deliverAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.deliverAtDT.time"
						                   v-bind:value="localPurchaseOrder.deliverAtDT.time"
						                   v-on:input="updateDT('deliverAt', 'deliverAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="issuedBy" class="font-weight-bold">PO Issued By</label>
					<input type="text" id="issuedBy" class="form-control position-relative"
					       placeholder="Choose Among Your Staffs Who Issued This PO"
					       v-bind:class="invalidInputs.issuedBy"
					       v-bind:value="localPurchaseOrder.issuedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="issuedAtDate" class="font-weight-bold text-truncate">Date Issued</label>
						<b-form-datepicker id="issuedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.date"
						                   v-bind:value="localPurchaseOrder.issuedAtDT.date"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="issuedAtTime" class="font-weight-bold text-truncate">Time Issued</label>
						<b-form-timepicker id="issuedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.time"
						                   v-bind:value="localPurchaseOrder.issuedAtDT.time"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localPurchaseOrder.notes"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">PO Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="PO Not Yet Saved"
					       v-bind:value="localPurchaseOrder.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localPurchaseOrder.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localPurchaseOrder.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">PO Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="PO Not Yet Created"
					       v-bind:value="localPurchaseOrder.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localPurchaseOrder.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localPurchaseOrder.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
                <div>
                    <label for="createdAtDate" class="font-weight-bold text-truncate mb-0">Invoice and Receipt</label>
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-info btn-block text-uppercase font-weight-bolder rounded-pill">
                                View Invoice
                            </button>
                        </div>
                    </div>
                </div>
			</form>
		</div>
		<div class="table-selects scroll-bar position-relative w-50 h-100 ml-3 rounded">
			<table-select title="Supplier Quote" filter="quoteNo" sort="quoteNo"
			              v-bind:order="-1"
			              v-bind:fields="supplierQuoteFields"
			              v-bind:data-array="supplierQuotes"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localPurchaseOrder.supplierQuote]"
			              v-on:row-clicked="selectSupplierQuote($event)"
			              v-bind:style="{height: '50% !important'}"></table-select>
			<table-select title="Supplier" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="suppliersFields"
			              v-bind:data-array="suppliers"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localPurchaseOrder.supplier]"
			              v-on:row-clicked="selectSupplier($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
			<table-select title="Staff" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="staffsFields"
			              v-bind:data-array="staffs"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localPurchaseOrder.issuedBy]"
			              v-on:row-clicked="selectIssuedBy($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent, itemsDifferent, calculateCharges, discountInvalid } from "@/utility/helpers";

export default {
	name: "PurchaseOrderDetails",

	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},

	props: {
		// props from vue router route params
		purchaseOrderId: {
			type: String,
		}
	},

	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},

	created() {
		this.initPurchaseOrder();
		window.addEventListener("beforeunload", this.unloadHandler);
	},

	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},

	data() {
		return {
			localPurchaseOrder: {
				_id: "",
				poNo: "",
				supplierQuote: "",
				supplier: "",
				items: [
					{
						name: "",
						quantity: "",
						unitPrice: "",
					},
				],
				currency: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				totalAmount: "",
				paymentTerms: "",
				shippingAddress: "",
				deliverAt: "",
				issuedAt: "",
				createdAt: "",
				updatedAt: "",
				issuedBy: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				supplierName: "",
				quoteNo: "",
				issuedByName: "",
				createdByName: "",
				updatedByName: "",
				deliverAtDT: { date: "", time: "" },
				issuedAtDT: { date: "", time: "" },
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				supplier: "",
				supplierQuote: "",
				currency: "",
				paymentTerms: "",
				shippingAddress: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				deliverAtDT: { date: "", time: "" },
				// can be invalid but not required
				issuedAtDT: { date: "", time: "" },
				issuedBy: "",
			},
			invalidItems: [
				{
					name: "",
					quantity: "",
					unitPrice: "",
				},
			],
			suppliersFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Supplier No.", key: "supplierNo", classBinding: ["f-3"] },
			],
			supplierQuoteFields: [
				{ display: "Quote No.", key: "quoteNo", classBinding: ["f-3"] },
				{ display: "Supplier", key: "supplier", classBinding: ["f-4"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
			],
			staffsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
				{ display: "Gender", key: "gender", classBinding: [] },
				{ display: "Position", key: "position", classBinding: ["f-4"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			poNo: "",
			poNoInvalid: false,
		}
	},

	computed: {
		purchaseOrder() {
			return this.$store.state.contents.purchaseOrders.find(({ _id }) => _id === this.purchaseOrderId);
		},

		savable() {
			// fall through pattern
			if (!this.purchaseOrder) return true;
			// can safely access properties inside this.purchaseOrder due to the first if clause in this function
			if (itemsDifferent(this.localPurchaseOrder.items, this.purchaseOrder.items)) return true;
			const dtKeys = ["deliverAt", "issuedAt"];
			if (dtKeys.some(key => {
				if (dateTimeDifferent(this.purchaseOrder[key], this.localPurchaseOrder[key])) return true;
			})) return true;
			const keys = ["supplier", "supplierQuote", "currency", "serviceFee", "shippingFee", "tax", "otherCharges",
				"discount", "paymentTerms", "shippingAddress", "issuedBy", "notes"];
			return keys.some(key => this.localPurchaseOrder[key] !== this.purchaseOrder[key]);
		},

		suppliers() {
			return this.$store.state.contents.suppliers.map(({ _id, name, supplierNo }) => {
				return {
					_id: _id,
					name: name,
					supplierNo: supplierNo,
				}
			});
		},

		supplierQuotes() {
			return this.$store.state.contents.supplierQuotations.map(({ _id, quoteNo, supplier, items, currency, totalAmount}) => {
				const supplierFound = this.$store.state.contents.suppliers.find(({ _id }) => _id === supplier);
				return {
					_id: _id,
					quoteNo: quoteNo,
					supplier: supplierFound ? `${supplierFound.name} (${supplierFound.supplierNo})` : "N/A",
					items: items.length,
					currency: currency,
					totalAmount: totalAmount
				}
			});
		},

		staffs() {
			return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
				return {
					_id: _id,
					name: `${lastName} ${firstName}, ${preferredName}`,
					staffNo: staffNo,
					gender: gender,
					position: position
				};
			});
		},
	},

	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},

		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.poNo = "";
				this.poNoInvalid = false;
			}
		},

		initPurchaseOrder() {
			if (this.purchaseOrder) {
				const obj = {...this.purchaseOrder};
				obj.items = [];
				this.invalidItems = [];
				this.purchaseOrder.items.forEach(item => {
					obj.items.push({...item});
					this.invalidItems.push({ name: "", quantity: "", unitPrice: "" });
				});
				const supplier = this.$store.state.contents.suppliers.find(({ _id }) => _id === obj.supplier);
				const supplierQuote = this.$store.state.contents.supplierQuotations.find(({ _id }) => _id === obj.supplierQuote);
				const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.issuedBy);
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// deliverAt, createdAt and updatedAt must present in the database, need no nullish check
				const deliverAtDT = new Date(obj.deliverAt);
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.supplierName = supplier ? `${supplier.name} (${supplier.supplierNo})` : "";
				obj.quoteNo = supplierQuote ? supplierQuote.quoteNo : "";
				obj.issuedByName = issuedBy ? `${issuedBy.lastName} ${issuedBy.firstName}, ${issuedBy.preferredName} (${issuedBy.staffNo})` : "";
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.deliverAtDT = { date: format(deliverAtDT, "yyyy-MM-dd"), time: format(deliverAtDT, "HH:mm:ss")};
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				if (obj.issuedAt) {
					const issuedAtDT = new Date(obj.issuedAt);
					obj.issuedAtDT = { date: format(issuedAtDT, "yyyy-MM-dd"), time: format(issuedAtDT, "HH:mm:ss")};
				} else {
					obj.issuedAtDT = { date: "", time: "" };
				}
				this.localPurchaseOrder = obj;
			} else {
				this.localPurchaseOrder._id = uuidv4();
				this.localPurchaseOrder.poNo = "PO-" + this.localPurchaseOrder._id.split("-")[0].toUpperCase();
			}
		},

		addDeleteItems(option, index) {
			if (option === "add") {
				this.localPurchaseOrder.items.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
				this.invalidItems.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
			} else {
				this.localPurchaseOrder.items.splice(index, 1);
				this.invalidItems.splice(index, 1);
			}
		},

		selectSupplier(supplier) {
			this.localPurchaseOrder.supplier = supplier._id;
			this.localPurchaseOrder.supplierName = `${supplier.name} (${supplier.supplierNo})`;
			this.invalidInputs.supplier = "";
		},

		selectSupplierQuote(quote) {
			this.localPurchaseOrder.supplierQuote = quote._id;
			this.localPurchaseOrder.quoteNo = quote.quoteNo;
			this.invalidInputs.supplierQuote = "";
		},

		selectIssuedBy(staff) {
			this.localPurchaseOrder.issuedBy = staff._id;
			this.localPurchaseOrder.issuedByName = `${staff.name} (${staff.staffNo})`;
			this.invalidInputs.issuedBy = "";
		},

		// this method also set discount class to invalid if discountInvalid returns true
		updateTotalAmount() {
			const totalCharges = calculateCharges(this.localPurchaseOrder);
			if (discountInvalid(totalCharges, this.localPurchaseOrder.discount)) {
				this.invalidInputs.discount = "invalid";
				this.localPurchaseOrder.totalAmount = totalCharges.toFixed(2);
			} else {
				this.invalidInputs.discount = "";
				const totalAmount = totalCharges - parseFloat(this.localPurchaseOrder.discount);
				this.localPurchaseOrder.totalAmount = totalAmount.toFixed(2);
			}
		},

		updatePurchaseOrder(key, value) {
			this.localPurchaseOrder[key] = value;
			switch (key) {
				case "serviceFee":
				case "shippingFee":
				case "tax":
				case "otherCharges":
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localPurchaseOrder[key] = parseFloat(value).toFixed(2);
						this.invalidInputs[key] = "";
					} else {
						this.invalidInputs[key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				case "discount":
					if (isNumeric(value) && parseFloat(value) >= 0) this.localPurchaseOrder[key] = parseFloat(value).toFixed(2);
					this.updateTotalAmount();
					break;
				default:
					// check if value is just white spaces
					this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},

		updateDT(key, keyDT, dateTime, value) {
			this.localPurchaseOrder[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localPurchaseOrder[key] = this.localPurchaseOrder[keyDT].time ?
					new Date(`${value}T${this.localPurchaseOrder[keyDT].time}`).toISOString() : value;
			} else {
				this.localPurchaseOrder[key] = this.localPurchaseOrder[keyDT].date ?
					new Date(`${this.localPurchaseOrder[keyDT].date}T${value}`).toISOString() : value;
			}
		},

		updateItems(key, value, index) {
			this.localPurchaseOrder.items[index][key] = value;
			switch (key) {
				case "quantity":
					this.invalidItems[index][key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
					this.updateTotalAmount();
					break;
				case "unitPrice":
					// bind to change event instead of input event
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localPurchaseOrder.items[index][key] = parseFloat(value).toFixed(2);
						this.invalidItems[index][key] = "";
					} else {
						this.invalidItems[index][key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				default:
					this.invalidItems[index][key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},

		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const orderKeys = ["supplier", "supplierQuote", "currency", "paymentTerms", "serviceFee", "shippingFee", "tax",
				"otherCharges", "discount", "shippingAddress"];
			const itemKeys = ["name", "quantity", "unitPrice"];
			orderKeys.forEach(key => {
				if (!this.localPurchaseOrder[key] ||
					this.localPurchaseOrder[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.invalidItems.forEach((item, index) => itemKeys.forEach(key => {
				if (!this.localPurchaseOrder.items[index][key] ||
					this.localPurchaseOrder.items[index][key].trim().length === 0) item[key] = "invalid"; //object reference
			}));
			if (this.localPurchaseOrder.issuedBy || this.localPurchaseOrder.issuedAt) {
				if (!this.localPurchaseOrder.issuedBy)this.invalidInputs.issuedBy = "invalid";
				if (!this.localPurchaseOrder.issuedAtDT.date) this.invalidInputs.issuedAtDT.date = "invalid";
				if (!this.localPurchaseOrder.issuedAtDT.time) this.invalidInputs.issuedAtDT.time = "invalid";
			}
			if (this.localPurchaseOrder.deliverAt) {
				if (!this.localPurchaseOrder.deliverAtDT.date) this.invalidInputs.deliverAtDT.date = "invalid";
				if (!this.localPurchaseOrder.deliverAtDT.time) this.invalidInputs.deliverAtDT.time = "invalid";
			} else {
				this.invalidInputs.deliverAtDT.date = "invalid";
				this.invalidInputs.deliverAtDT.time = "invalid";
			}
			return Object.keys(this.invalidInputs).some(key => {
				if (key.includes("DT")) {
					return this.invalidInputs[key].date === "invalid" ||
						this.invalidInputs[key].time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			}) || this.invalidItems.some(item => itemKeys.some(key => item[key] === "invalid"));
		},

		async savePurchaseOrder() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["supplierName", "quoteNo", "issuedByName", "createdByName", "updatedByName",
					"deliverAtDT", "issuedAtDT", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const purchaseOrder = {...this.localPurchaseOrder};
				if (!purchaseOrder.createdAt) {
					purchaseOrder.createdAt = now;
					purchaseOrder.createdBy = this.$store.state.user.user._id;
				}
				purchaseOrder.updatedAt = now;
				purchaseOrder.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete purchaseOrder[key]);
				await this.$store.dispatch("contents/updateContent", { key: "purchaseOrders", value: purchaseOrder});
				if (this.purchaseOrderId) {
					this.initPurchaseOrder();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/supplier/order/${purchaseOrder._id}`);
					// need to run initPurchaseOrder after route change since purchaseOrderId prop was undefined
					this.initPurchaseOrder();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Purchase Order Created!";
				}
			}
		},

		async deletePurchaseOrder(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.poNo !== this.purchaseOrder.poNo) this.poNoInvalid = true;
			} else if (this.poNo !== this.purchaseOrder.poNo) {
				bvModalEvent.preventDefault();
				this.poNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "purchaseOrders", _id: this.purchaseOrder._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/supplier/order");
			}
		},

		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/supplier/order");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
@import "src/assets/scss/table_overflow";
</style>
